<template>
  <input :type="props.type ?? 'text'" class="text-input bg-transparent" :class="props.class" :min="props.min" :maxlength="props.maxLength" v-model="props.modelValue" @input="update($event)" @blur="blur" @focus="internalFocus" @keyup.enter="enterPressed" @keyup.escape="escapePressed" @keyup.arrowdown="downPressed" @keyup.arrowup="upPressed" @keyup="keyPressed" :autocomplete="props.autocomplete ?? 'off'" :placeholder="props.placeholder" ref="element" :disabled="props.disabled" />
</template>
<script setup>
const props = defineProps({
  modelValue: String,
  placeholder: String,
  type: String,
  min: String,
  class: String,
  maxLength: Number | undefined,
  disabled: Boolean | undefined,
  autocomplete: String | undefined
});

const element = ref(null);

const emit = defineEmits(['update:modelValue', 'validate', 'enter', 'escape', 'focus', 'blur', 'down', 'up', 'keyPressed']);

const internalFocus = () => {
  emit('focus');
}

const blur = ($event) => {
  emit('update:modelValue', $event?.target.value ?? '');
  emit('validate');
  emit('blur');
}

const blurWithoutValidation = () => {
  element.value.blur();
}

const update = ($event) => emit('update:modelValue', $event.target.value);

const focus = () => {
  element.value.focus();
}

defineExpose({ focus, blurWithoutValidation });

const enterPressed = () => {
  emit('enter');
}

const escapePressed = () => {
  emit('escape');
}

const downPressed = () => {
  emit('down');
}

const upPressed = () => {
  emit('up');
}

const keyPressed = (code) => {
  if (code !== 'ArrowUp' && code !== 'ArrowDown' && code !== 'Escape' && code !== 'Enter') {
    emit('keyPressed', code);
  }
}

</script>
<style lang="postcss">
.text-input {
  @apply px-3 py-2 outline-none border-2 border-neutral-200;
}
</style>